import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { Header } from './Header';
import { NavTabs } from './NavTabs';

export function TabsPagesLayout() {
  const { t } = useTranslation('settings');

  const tabs = [
    { label: t('users'), path: '/settings' },
    { label: t('labels'), path: '/settings/labels' },
  ];

  return (
    <>
      <Header />
      <NavTabs tabs={tabs} ariaLabel={t('settingsTabLabel')} />
      <Outlet />
    </>
  );
}

TabsPagesLayout.displayName = 'TabsPagesLayout';

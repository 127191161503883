import { createColumnHelper } from '@tanstack/react-table';

import type { Label } from '~/modules/settings/types';

import { GroupInfo } from './GroupInfo';
import { LabelInfo } from './LabelInfo';

const columnHelper = createColumnHelper<Label>();

export const columns = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    cell: (info) => <LabelInfo data={info.row} />,
    header: () => 'Label',
  }),

  columnHelper.accessor((row) => row.type, {
    id: 'type',
    cell: (cell) => cell.getValue(),
    header: () => 'Label type',
  }),
  columnHelper.accessor((row) => row.groupsNames, {
    id: 'groups',
    cell: (info) => <GroupInfo data={info.row} />,
    header: () => 'Label group',
  }),
  columnHelper.accessor((row) => row.usersCount, {
    id: 'users',
    cell: (cell) => {
      return cell.getValue() ? cell.getValue() : '-';
    },
    header: () => 'Users',
  }),
  columnHelper.accessor((row) => row.sublabelsCount, {
    id: 'labels',
    cell: (cell) => {
      return cell.getValue() ? cell.getValue() : '-';
    },
    header: () => 'Sublabels',
  }),
];

import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

import type { IconName, IconVariant } from '~/components/Icon';
import { Icon } from '~/components/Icon';
import { Tooltip } from '~/components/Tooltip';
import { mergeClasses } from '~/lib/mergeClasses';
import type { VariantProps } from '~/lib/variants';
import { variants } from '~/lib/variants';

const button = variants({
  base: [
    'min-h-lg',
    'min-w-lg',
    'overflow-clip',
    'flex-shrink-0',
    'flex',
    'flex-row',
    'justify-start',
    'items-center',
    'gap-md',
    'text-white',
    'text-start',
    'text-nowrap',
    'focus:outline-none',
  ],
  variants: {
    variant: {
      default: [
        'py-xs',
        'bg-transparent',
        'hover:bg-default-inverse/10',
        'focus:bg-primary-default',
        'active:bg-primary-default',
        'justify-center',
        'px-0',
        'rounded-xs',
      ],
      primary: [
        'px-md',
        'gap-0',
        'flex-row-reverse',
        'justify-between',
        'font-medium',
        'bg-primary-default',
        'hover:bg-primary-hover',
        'focus:bg-primary-hover',
        'active:bg-primary-pressed',
        'rounded-xs',
      ],
      active: [
        'py-xs',
        'hover:bg-default-inverse/10',
        'focus:bg-primary-default',
        'bg-primary-default',
        'justify-center',
        'px-0',
        'rounded-xs',
      ],
    },
  },
});

type ButtonVariants = VariantProps<typeof button>;

interface SidebarMenuItemProps
  extends Omit<ButtonVariants, 'onlyIcon'>,
    LinkProps {
  icon: IconName;
  iconVariant?: IconVariant;
}

export function SidebarMenuItem({
  icon,
  className,
  children,
  iconVariant = 'solid',
  variant = 'default',
  to,
  ...props
}: SidebarMenuItemProps) {
  return (
    <Tooltip
      content={
        <span
          className="flex items-center gap-xs border-solid border-default border-px border-opacity-50 rounded-xs p-xs"
          aria-hidden="true"
        >
          {children}
        </span>
      }
      delayDuration={0}
      enabled
      side="right"
      triggerAsChild
    >
      <Link
        to={to}
        className={mergeClasses(button({ variant }), className)}
        {...props}
      >
        <Icon
          type={icon}
          variant={iconVariant}
          className="text-display-sm px-sm py-md box-content"
          aria-hidden="true"
        />
        <span className="sr-only">{children}</span>
      </Link>
    </Tooltip>
  );
}

SidebarMenuItem.displayName = 'SidebarMenuItem';

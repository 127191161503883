import type { FC, PropsWithChildren } from 'react';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { ComingSoon } from '~/components/WmgOne/ComingSoon.tsx';
import { BrandContext } from '~/modules/branding';
import { NoBrandError } from '~/modules/errors/components/NoBrandError.tsx';

const RootLayout: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const brand = useContext(BrandContext);

  if (brand.name === 'WMG') {
    return (
      <>
        <Helmet>
          <title>{brand.title}</title>
          <link rel="icon" type="image/png" href="/images/wmgone-fav.png" />
        </Helmet>
        <ComingSoon />
      </>
    );
  }
  if (brand.name === 'ADA') {
    return (
      <>
        <Helmet>
          <title>{brand.title}</title>
          <link rel="icon" type="image/png" href="/images/ada_logo.png" />
        </Helmet>
        <section className="bg-default text-default min-w-full min-h-dvh flex">
          {children}
        </section>
      </>
    );
  }
  return <NoBrandError />;
};

RootLayout.displayName = 'RootLayout';

export { RootLayout };

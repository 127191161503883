import type { IconName } from '~/components/Icon';
import { NAVIGATION_PERMISSIONS } from '~/lib/navigationPermissions.ts';
import type { HasPermissionFunction } from '~/modules/remotes/types.ts';

export const SIDEBAR_NAV_ITEMS: {
  to: string;
  icon: IconName;
  label: string;

  /**
   * Additional routes that will be considered as active when the current route matches.
   */
  matchRoutes?: RegExp[];
  title?: string;
  permission?: (
    hasPermission: HasPermissionFunction,
    context?: Record<string, unknown>,
  ) => boolean;
}[] = [
  {
    to: '/',
    icon: 'list-music',
    label: 'dashboard',
    title: 'dashboard',
  },
  {
    to: '#bulk_upload_modal',
    matchRoutes: [/uploads/],
    icon: 'folder-arrow-up',
    label: 'bulkUpload',
    title: 'bulkUpload',
    permission: NAVIGATION_PERMISSIONS.BULK_UPLOAD_MODAL,
  },
  {
    to: '/release-clinic',
    icon: 'truck-medical',
    label: 'releaseClinic',
    title: 'releaseClinic',
    permission: NAVIGATION_PERMISSIONS.RELEASE_CLINIC,
  },
  {
    to: '/video-management',
    icon: 'clapperboard-play',
    label: 'video',
    title: 'video',
    permission: NAVIGATION_PERMISSIONS.VIDEO_MANAGEMENT,
  },
  {
    to: '/reports',
    icon: 'chart-mixed',
    label: 'reports',
    title: 'reports',
    permission: NAVIGATION_PERMISSIONS.REPORTS,
  },
  {
    to: '/release/settings/core-details',
    icon: 'file-music',
    label: 'createRelease',
    title: 'createRelease',
    permission: NAVIGATION_PERMISSIONS.CREATE_RELEASE,
  },
  {
    to: '/platform/settings',
    icon: 'gear',
    label: 'settings',
    title: 'settings',
    permission: NAVIGATION_PERMISSIONS.COOP_SETTINGS,
  },
  {
    to: '/settings',
    icon: 'user-gear',
    label: 'settingsNew',
    title: 'settingsNew',
    permission: NAVIGATION_PERMISSIONS.SETTINGS,
  },
];

import type { Row } from '@tanstack/react-table';

import type { User } from './Users/types';

export interface BaseEntity {
  id: string;
  name: string;
}

export enum LabelType {
  PARENT_LABEL = 'Parent label',
  SUBLABEL = 'Sublabel',
}

export interface Label {
  id: string;
  name: string;
  isActive: boolean;
  type: LabelType;
  usersCount?: number;
  sublabelsCount: number;
  groupsNames?: string[] | null;
}

export interface LabelDetailed extends Label {
  parent: BaseEntity | null;
  parentName: string | null;
  weaLabelCode: string;
  dealRegionScope: string;
  dealOwner: string;
  distributionGroups: Array<{
    id: string;
    name: string;
    distributionGroupCountries: Array<{
      country: {
        id: string;
        name: string;
      };
    }>;
  }>;
  sublabels: BaseEntity[] | null;
  socials?: Array<[string, string]>;
  users: User[];
}

export type LabelRow = Row<Label>;

import { Trans, useTranslation } from 'react-i18next';

const NoAccessPage = () => {
  const { t } = useTranslation('errors');

  return (
    <div
      className="h-screen w-full bg-gradient flex marker:flex-col items-center bg-cover bg-no-repeat bg-bottom whitespace-break-spaces"
      data-testid="NoAccessPage"
    >
      <div className="ml-3xl mb-lg">
        <p className="font-header text-display-5xl m-0 leading-none uppercase">
          <Trans
            t={t}
            i18nKey="noAccessPage.header"
            components={{ br: <br /> }}
          />
        </p>
        <p className="text-body-md mt-sm">
          {t('noAccessPage.permissionError')}
        </p>
      </div>
    </div>
  );
};

NoAccessPage.displayName = 'NoAccessPage';

export { NoAccessPage };

import { useOktaAuth } from '@okta/okta-react';
import {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { convertCoopLocale } from '~/i18n';
import { env } from '~/lib/env';
import { useShowNav } from '~/modules/navigation';

import { ErrorPage as ErrorScreen } from '../../../components/ErrorPage/ErrorPage';
import { LoadingScreen } from '../../navigation/components/LoadingScreen';
import { bffClient, type UserResponse } from '../lib/bff-client';
import { WmgSessionContext } from './WmgSessionContext';

const WmgSessionProvider = ({ children }: PropsWithChildren) => {
  const { authState } = useOktaAuth();
  const [error, setError] = useState<string | undefined>();
  const [user, setUser] = useState<UserResponse | undefined>();
  const { setShowNav, setShowByLocale } = useShowNav();

  const useContainerNav =
    env('VITE_USE_CONTAINER_NAVIGATION', false) === 'true';
  const bffGracefulFallback =
    env('VITE_BFF_GRACEFUL_FALLBACK', false) === 'true';
  const [authAttempted, setAuthAttempted] = useState(false);

  const token = authState?.accessToken?.accessToken;
  const uid = authState?.accessToken?.claims?.uid as string;

  const authenticateUser = useCallback(
    async (token?: string, oktaUserId?: string) => {
      if (!token || !oktaUserId) {
        return;
      }

      setError(undefined);

      if (useContainerNav) {
        try {
          const fetchedUser = await bffClient.getUser(token);
          const fetchUserLocale = await bffClient.getUserLocale(
            token,
            oktaUserId,
          );
          setUser(fetchedUser);
          setShowByLocale(convertCoopLocale(fetchUserLocale));
        } catch (e) {
          if (bffGracefulFallback) {
            setShowNav(false);
          } else {
            setError((e as Error).message || 'Unexpected error');
            setShowNav(true);
          }
        } finally {
          setAuthAttempted(true);
        }
      } else {
        setShowNav(false);
        setAuthAttempted(true);
      }
    },
    [useContainerNav, setShowByLocale, bffGracefulFallback, setShowNav],
  );

  useEffect(() => {
    authenticateUser(token, uid);
  }, [authenticateUser, token, uid]);

  if (error) {
    return (
      <ErrorScreen
        message={error}
        onRetry={() => authenticateUser(token, uid)}
      />
    );
  }

  if (
    authState?.accessToken?.accessToken &&
    !user &&
    useContainerNav &&
    !authAttempted
  ) {
    return <LoadingScreen />;
  }

  const wmgUser = user ?? {
    permissions: {},
    name: '',
  };

  return (
    <WmgSessionContext.Provider value={{ user: wmgUser!, token: token ?? '' }}>
      {children}
    </WmgSessionContext.Provider>
  );
};

WmgSessionProvider.displayName = 'WmgSessionProvider';

export { WmgSessionContext, WmgSessionProvider };

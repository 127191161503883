import type {
  BaseEntity,
  Label,
  LabelDetailed,
} from '~/modules/settings/types';
import { LabelType } from '~/modules/settings/types';
import type { User } from '~/modules/settings/Users/types';

export const transformUsers = (data: { [key: string]: unknown }[] = []) =>
  data.map(
    (user): Partial<User> =>
      ({
        id: user.id,
        fullName: user.fullName,
        firstName: user.firstName,
        lastName: user.lastName,
        isActive: user.isActive,
        email: user.email,
        isExternal: user.external,
        labelGroups: (user.labelGroups as Array<{ name: string }>).map(
          (g) => g.name,
        ),
        labelsNames: user.labelsNames,
        lastLogin: user.lastLogin,
        roles: user.roles ?? [],
      }) as User,
  );

export const transformLabels = (data: { [key: string]: unknown }[] = []) => {
  return data.map(
    (label): Partial<Label> =>
      ({
        id: label.id,
        name: label.name,
        isActive: label.active,
        type: label.isParentLabel ? LabelType.PARENT_LABEL : LabelType.SUBLABEL,
        usersCount: label.usersCount,
        sublabelsCount: label.sublabelsCount,
        groupsNames: (label.labelGroups as Array<BaseEntity>)?.map(
          (g) => g.name,
        ),
      }) as Label,
  );
};

export const transformLabelDetails = (data: {
  [key: string]: unknown;
}): LabelDetailed => {
  const socials = [
    'facebookReference',
    'twitterReference',
    'website',
    'youtubeReference',
    'instagramReference',
  ].reduce((acc: Array<[string, string]>, key) => {
    if (data[key]) {
      let value = data[key] as string;
      if (!/^(https?:)/.test(value)) {
        value = `https://${value}`;
      }
      acc.push([key, value]);
    }
    return acc;
  }, []);

  const parent = data.parent
    ? {
        id: (data.parent as BaseEntity)?.id,
        name: (data.parent as BaseEntity)?.name,
      }
    : undefined;

  return {
    id: data.id,
    name: data.name,
    isActive: data.active,
    type: (data.parent as BaseEntity)?.id
      ? LabelType.SUBLABEL
      : LabelType.PARENT_LABEL,
    parent,
    parentName: data.parentName,
    sublabels: data.sublabels,
    sublabelsCount: (data.sublabels as Array<BaseEntity>)?.length,
    groupsNames: (data.labelGroups as Array<BaseEntity>)?.map((g) => g.name),
    dealRegionScope: data.dealRegionScope,
    dealOwner: data.dealOwner,
    weaLabelCode: data.weaLabelCode,
    distributionGroups: data.distributionGroups,
    socials,
    users: transformUsers(data.users as { [key: string]: unknown }[]),
  } as LabelDetailed;
};

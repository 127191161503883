import { remotes } from '~/modules/remotes/registry';

const containerPaths = [
  '',
  'login',
  'logout',
  'auth/refresh',
  'settings',
  '_dev',
];

const KNOWN_PATHS = new Set(
  remotes
    .flatMap((remote) => remote.path)
    .map((path) => path.replace(/\/\*$/, ''))
    .concat(...containerPaths),
);

const HOMEPAGE_ROOT = '';

const isKnownPath = (path: string) => {
  // check if OK domains
  const userPath = path.trim().split('/');
  userPath.shift();

  const subRoot = userPath[0];
  const isHomePage = subRoot === HOMEPAGE_ROOT;

  return isHomePage || KNOWN_PATHS.has(subRoot);
};

export { isKnownPath };

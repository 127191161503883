import type { UserRow } from '~/modules/settings/Users/types';

export const UserInfo = ({ data }: { data: UserRow }) => {
  const { fullName, isActive, firstName, lastName } = data.original;
  const statusBaseCls = 'rounded-full flex-shrink-0 basis-4 w-sm h-sm ml-sm';
  const statusCls = isActive ? 'bg-success-default' : 'bg-error-default';

  const initials = firstName[0]?.toUpperCase() + lastName[0]?.toUpperCase();

  return (
    <div className="flex items-center">
      <span className="mr-sm w-lg h-lg basis-4 rounded-full leading-6 bg-level-05 text-body-xs text-center">
        {initials}
      </span>
      <div className="flex items-center">
        <span className="flex-shrink-0 max-w-fit">{fullName}</span>
        <span className={`${statusBaseCls} ${statusCls}`} />
      </div>
    </div>
  );
};

UserInfo.displayName = 'UserInfo';

import { Trans, useTranslation } from 'react-i18next';

import { AdaLogo } from '../AdaLogo';

interface ErrorPageProps {
  message?: string;
  onRetry: () => void;
}

const ErrorPage = ({ message, onRetry }: ErrorPageProps) => {
  const { t } = useTranslation('errors');

  return (
    <div className="absolute top-0 left-0 flex flex-col justify-center w-full h-full bg-default">
      <span className="w-full flex flex-row items-center h-3xl py-lg pl-md text-primary-default">
        <AdaLogo className="h-lg" />
      </span>
      <div className="h-full w-full bg-gradient flex marker:flex-col items-center bg-cover bg-no-repeat bg-bottom whitespace-break-spaces">
        <div className="ml-3xl">
          <div className="mb-lg" data-testid="Error Banner">
            <p className="font-header text-display-5xl m-0 leading-none">
              <Trans t={t} i18nKey="header" components={{ br: <br /> }} />
            </p>
            <p className="text-body-md mt-sm">{t('description')}</p>
            {message && <p className="text-body-sm mt-sm">{message}</p>}
          </div>
          <button
            className="py-sm px-md text-default bg-primary-default hover:bg-primary-hover focus:bg-primary-hover active:bg-primary-pressed disabled:text-disabled disabled:bg-primary-disabled rounded-full"
            onClick={onRetry}
          >
            {t('retryButton')}
          </button>
        </div>
      </div>
    </div>
  );
};

ErrorPage.displayName = 'ErrorPage';

export { ErrorPage };

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AdaLogo } from '~/components/AdaLogo';
import { Avatar } from '~/components/Avatar';
import { Popover } from '~/components/Popover';
import { SIDEBAR_NAV_ITEMS } from '~/lib/nav.ts';
import { useUser } from '~/modules/auth/hooks/useUser.ts';
import { useShowNav } from '~/modules/navigation';
import { useSelected } from '~/modules/navigation/components/PrimaryLayout/hooks/useSelected.ts';
import {
  Sidebar,
  SidebarMenuItem,
} from '~/modules/navigation/components/Sidebar';
import { SidebarPopoverContent } from '~/modules/navigation/components/Sidebar/parts/SidebarPopoverContent';
import { dataTestIds } from '~/test-utils/dataTestIds.ts';

export function Nav() {
  const { t } = useTranslation('navigation', {
    keyPrefix: 'sidebar.items',
  });
  const { showNav } = useShowNav();
  const { hasPermission, ...user } = useUser();
  const { name } = user;
  const permittedItems = SIDEBAR_NAV_ITEMS.filter(
    ({ permission }) => !permission || permission(hasPermission, { user }),
  );
  const { selected, refToBlur } = useSelected({ items: permittedItems });

  return (
    showNav && (
      <Sidebar>
        <div className="flex flex-shrink-0 justify-center items-center w-full pt-lg px-md pb-md">
          <Link to="/">
            <AdaLogo
              className="w-2xl h-2xl text-primary-default"
              aria-label="Ada logo"
            />
          </Link>
        </div>
        <div className="h-full flex flex-col gap-lg px-md pt-sm">
          {permittedItems.map(({ to, icon, label, title }, index) => {
            const isActive = selected === index;
            const _title =
              title === 'dashboard' ? 'Dashboard' : title && t(title);

            return (
              <SidebarMenuItem
                key={label}
                to={to}
                icon={icon}
                title={_title}
                variant={isActive ? 'active' : 'default'}
                aria-current={isActive ? 'page' : undefined}
                onClick={(e) => {
                  refToBlur.current = e.currentTarget;
                }}
              >
                {t(label)}
              </SidebarMenuItem>
            );
          })}
        </div>
        <div data-testid={dataTestIds.USER_PROFILE_ICON}>
          <Popover
            content={(onItemClick) => (
              <SidebarPopoverContent name={name} onItemClick={onItemClick} />
            )}
          >
            <div className="px-md pb-lg">
              <Avatar name={name} data-testid="avatar-icon" />
            </div>
          </Popover>
        </div>
      </Sidebar>
    )
  );
}

Nav.displayName = 'Nav';

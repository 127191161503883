import { createColumnHelper } from '@tanstack/react-table';
import { format, formatDistanceToNow, parseISO } from 'date-fns';

import { Icon, Tooltip } from '~/components';
import type { User } from '~/modules/settings/Users/types';

import { UserInfo } from './UserInfo';

const columnHelper = createColumnHelper<User>();

export const columns = [
  columnHelper.accessor((row) => row.fullName, {
    id: 'fullName',
    cell: (info) => <UserInfo data={info.row} />,
    header: () => 'Name',
  }),

  columnHelper.accessor((row) => row.email, {
    id: 'email',
    cell: (cell) => cell.getValue(),
    header: () => 'Email',
  }),
  columnHelper.accessor(() => '', {
    id: 'labelGroups',
    cell: () => '',
    header: () => 'Label Group',
  }),
  columnHelper.accessor((row) => row.labelsNames, {
    id: 'labels',
    cell: (info) => info.getValue().join(' · '),
    header: () => 'Labels',
  }),
  columnHelper.accessor((row) => row.lastLogin, {
    id: 'lastLogin',
    cell: (info) => {
      const value = info.getValue();
      if (!value) return '';

      const date = parseISO(value);

      const formattedDate = format(date, 'MMMM d, yyyy  h:mm a');

      return (
        <span>
          {formatDistanceToNow(date, { addSuffix: true })}
          <Tooltip content={formattedDate} side="top" delayDuration={0}>
            <Icon
              type="circle-info"
              variant="light"
              className="text-subtlest ml-sm"
              size="sm"
            />
          </Tooltip>
        </span>
      );
    },
    header: () => 'Last Login',
  }),
];

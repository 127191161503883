import './style.css';

export function ComingSoon() {
  return (
    <div className="flex h-screen w-full bg-default bg-brand-wmg">
      <div className="flex flex-wrap gap-lg text-4xl text-white mt-[20em] pl-[4em]">
        <div className={'font-wmg'}>
          <h2>WMG</h2>
          <h2 className={'-mt-4xl'}>ONE</h2>
        </div>
      </div>
    </div>
  );
}

ComingSoon.displayName = 'ComingSoon';

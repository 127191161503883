import { Route, Routes } from 'react-router-dom';

import {
  NAVIGATION_PERMISSIONS,
  NavigationPermissionKeys,
} from '~/lib/navigationPermissions.ts';
import { Logout } from '~/modules/auth/components/Logout.tsx';
import { RefreshAuth } from '~/modules/auth/components/RefreshAuth.tsx';
import { SecureRoute } from '~/modules/auth/components/SecureRoute';
import { useUser } from '~/modules/auth/hooks/useUser.ts';
import { AuthRouter } from '~/modules/auth/router';
import { NoAccessPage } from '~/modules/errors/components/NoAccessPage.tsx';
import { RemoteRouter } from '~/modules/remotes/router';

import { PrimaryLayout } from './modules/navigation/components/PrimaryLayout';
import { App as SettingsApp } from './modules/settings/App';

/**
 * Top-level router combining module-level routers
 */
const Router = () => {
  const { hasPermission, ...user } = useUser();
  const canAccessSettings = NAVIGATION_PERMISSIONS[
    NavigationPermissionKeys.SETTINGS
  ](hasPermission, { user });

  return (
    <Routes>
      <Route path="login/*" element={<AuthRouter />} />
      <Route path="*" element={<SecureRoute />}>
        <Route path="logout" element={<Logout />} />
        <Route path="auth/refresh" element={<RefreshAuth />} />
        <Route path="*" element={<PrimaryLayout />}>
          <Route
            path="settings/*"
            element={canAccessSettings ? <SettingsApp /> : <NoAccessPage />}
          />
          <Route path="*" element={<RemoteRouter />} />
        </Route>
        <Route path="*" element={<NoAccessPage />} />
      </Route>
    </Routes>
  );
};

Router.displayName = 'Router';

export { Router };

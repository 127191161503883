import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import type { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';
import type { PropsWithChildren } from 'react';

import { oktaAuth } from '../lib/okta-auth';

/**
 * @see https://github.com/okta/okta-react?tab=readme-ov-file#restoreoriginaluri
 */
const restoreOriginalUri: RestoreOriginalUriFunction = (
  _oktaAuth,
  originalUri,
) => {
  const relativeURI = toRelativeUrl(originalUri || '/', window.location.origin);
  if (relativeURI !== window.location.pathname) {
    window.location.replace(relativeURI);
  }
};

/**
 * Connects the Okta Security provider.
 */
const AuthenticationProvider = ({ children }: PropsWithChildren) => (
  <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
    {children}
  </Security>
);

AuthenticationProvider.displayName = 'AuthenticationProvider';

export { AuthenticationProvider };

import type { PropsWithChildren } from 'react';
import { Component } from 'react';

import { logger } from '~/lib/logger';

class ErrorBoundary<
  T extends PropsWithChildren<{
    fallback?: PropsWithChildren<unknown>['children'];
    onError?: (error: Error) => void;
  }>,
> extends Component<T, { error?: string }> {
  static displayName = 'ErrorBoundary';
  constructor(props: T) {
    super(props);
    this.state = { error: undefined };
  }

  componentDidCatch(error: Error) {
    logger.error(error);
    this.setState({ error: `${error.name}: ${error.message}` });
    this.props.onError?.(error);
  }

  render() {
    const { error } = this.state;
    if (error) {
      return this.props.fallback || <div>{error}</div>;
    } else {
      return <>{this.props.children}</>;
    }
  }
}

export { ErrorBoundary };

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { BffAction } from '~settings/modules/types';

import { useBffClient } from '../hooks/useBffClient';
import { LabelMetadata } from './LabelMetadata';

export const LabelDetails = () => {
  const { uuid } = useParams();

  const fetchLabels = useBffClient(BffAction.GET_LABEL_DETAILS);
  const { data, isLoading } = useQuery({
    queryKey: ['labelDetails', uuid],
    queryFn: () => fetchLabels(uuid),
  });

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }

  return (
    <>
      <header>
        <h1>{data.name}</h1>
      </header>
      <article className="py-md flex gap-md">
        <section className="text-body-sm flex-none basis-[20%] min-w-[19rem] inline-flex flex-col gap-md">
          <div className="bg-level-02 rounded-sm p-md">
            <LabelMetadata data={data} />
          </div>
          {/* Label admins component goes here */}
        </section>
        <section className="bg-level-02 flex-1 rounded-sm">
          {/* Users Labels tabs going here */}
        </section>
      </article>
    </>
  );
};

LabelDetails.displayName = 'LabelDetails';

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import type { User } from '~/modules/settings/Users/types.ts';
import { columns } from '~/modules/settings/Users/UsersTable/columns.config';

interface Props {
  users: User[];
}
export const UsersTable = ({ users }: Props) => {
  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className="w-full">
      <thead className="text-sm text-left leading-tight">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="px-md py-sm text-subtle body-sm font-normal"
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="text-md">
        {users.length ? (
          table.getRowModel().rows.map((row, i) => {
            return (
              <tr key={row.id} className={i % 2 === 0 ? 'bg-level-02' : ''}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="p-md">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })
        ) : (
          <tr>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            <td colSpan={columns.length} className="p-md">
              No data
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

UsersTable.displayName = 'UsersTable';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Tag } from '~/components';
import type { LabelDetailed } from '~settings/types';

type DetailsKeys = keyof Pick<
  LabelDetailed,
  'weaLabelCode' | 'dealOwner' | 'dealRegionScope' | 'type' | 'parentName'
>;

const translationsMap: Record<DetailsKeys, string> = {
  weaLabelCode: 'weaLabelCode',
  dealOwner: 'dealOwner',
  dealRegionScope: 'dealScope',
  type: 'labelType',
  parentName: 'parentLabel',
};

export const LabelMetadata = ({ data }: { data: LabelDetailed }) => {
  const { t } = useTranslation('settings');

  // todo: create map of items to show based on external/internal user type
  const keysToRender: DetailsKeys[] = useMemo(
    () =>
      (Object.keys(translationsMap) as DetailsKeys[]).filter(
        (k) => data[k] !== undefined && data[k] !== null,
      ),
    [data],
  );

  return (
    <div>
      <h6 className="text-headline-sm mb-md mb-5 font-semibold leading-tight">
        {t('labelDetails')}
      </h6>
      <dl className="flex flex-col gap-md">
        {keysToRender.map((key) => (
          <dt key={key} className="flex justify-between gap-sm">
            <span className="text-subtle">{t(translationsMap[key])}</span>
            {key === 'parentName' ? (
              <Tag variant="secondary" size="sm" asChild>
                <Link to={`/settings/labels/${data.parent?.id}`}>
                  {data.parentName}
                </Link>
              </Tag>
            ) : (
              <span>{data[key]}</span>
            )}
          </dt>
        ))}
      </dl>
    </div>
  );
};

LabelMetadata.displayName = 'LabelMetadata';

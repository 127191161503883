import type React from 'react';
import { useState } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Icon } from '~/components/Icon';
import { mergeClasses } from '~/lib/mergeClasses';

interface HoverIconItemProps
  extends Omit<LinkProps, 'onMouseEnter' | 'onMouseLeave'> {
  icon: string;
  text: string;
  'data-testid'?: string;
}

export const HoverIconItem: React.FC<HoverIconItemProps> = ({
  icon,
  text,
  className,
  'data-testid': dataTestId,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link
      className={mergeClasses(
        'text-default',
        'no-underline',
        'text-caption-md',
        'flex',
        'items-center',
        'cursor-pointer',
        className,
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      <Icon
        type={icon}
        variant={isHovered ? 'solid' : 'regular'}
        className="pr-sm"
        data-testid={dataTestId}
      />
      <span className="hover:underline">{text}</span>
    </Link>
  );
};

HoverIconItem.displayName = 'HoverIconItem';

import { useQuery } from '@tanstack/react-query';

import { useBffClient } from '~/modules/settings/hooks/useBffClient';
import { BffAction } from '~/modules/settings/modules/types.ts';
import { UsersTable } from '~/modules/settings/Users/UsersTable';

export const Users = () => {
  const fetchUsers = useBffClient(BffAction.FETCH_USERS);
  const { data, isLoading } = useQuery({
    queryKey: ['users'],
    queryFn: fetchUsers,
  });

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }

  const users = data?.data ?? [];

  return (
    <section className="py-md">
      <UsersTable users={users} />
    </section>
  );
};

Users.displayName = 'Users';

import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useUser } from '~/modules/auth/hooks/useUser.ts';
import { ErrorPage } from '~/modules/errors/components/ErrorPage';
import { NoAccessPage } from '~/modules/errors/components/NoAccessPage.tsx';
import { useRemoteContexts } from '~/modules/remotes/hooks/use-remote-contexts.ts';
import type { IRemoteDeclaration } from '~/modules/remotes/types.ts';

import { ErrorBoundary } from '../errors/components/ErrorBoundary';
import { RemotesStatus } from './components/RemotesStatus';
import { remotes } from './registry';

const PrivateRemote = ({ remote }: { remote: IRemoteDeclaration }) => {
  const remoteContexts = useRemoteContexts();
  const { hasPermission, ...user } = useUser();

  if (remote?.permission(hasPermission, { user })) {
    return (
      <Suspense>
        <div
          className={[
            'w-full',
            remoteContexts[remote.name].isolateStyles ? 'no-preflight' : '',
          ].join(' ')}
        >
          <remote.root ctx={remoteContexts[remote.name].context} />
        </div>
      </Suspense>
    );
  }

  return <NoAccessPage />;
};

PrivateRemote.displayName = 'PrivateRemote';

/**
 * Uniform Router.
 */
const RemoteRouter = () => {
  return (
    <ErrorBoundary>
      <Routes>
        {remotes.map((remote) => (
          <Route
            key={remote.name}
            path={remote.path[0]}
            errorElement={<ErrorPage />}
            element={<PrivateRemote remote={remote} />}
          />
        ))}

        <Route path="_dev/remotes-status" element={<RemotesStatus />} />
      </Routes>
    </ErrorBoundary>
  );
};

RemoteRouter.displayName = 'RemoteRouter';

export { RemoteRouter };

import { useQuery } from '@tanstack/react-query';

import { useBffClient } from '~settings/hooks/useBffClient';
import { LabelsTable } from '~settings/Labels/LabelsTable';
import { BffAction } from '~settings/modules/types';

export const Labels = () => {
  const fetchLabels = useBffClient(BffAction.FETCH_LABELS);
  const { data, isLoading } = useQuery({
    queryKey: ['labels'],
    queryFn: fetchLabels,
  });

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }
  const labels = data?.data ?? [];

  return (
    <section className="py-md">
      <LabelsTable labels={labels} />
    </section>
  );
};

Labels.displayName = 'Labels';

import { useTranslation } from 'react-i18next';

export function NoBrandError() {
  const { t } = useTranslation('errors');

  return (
    <div id="no-brand-error-page">
      <h1>{t('noBrandPage.title')}</h1>
      <p>{t('noBrandPage.message')}</p>
    </div>
  );
}

NoBrandError.displayName = 'NoBrandError';

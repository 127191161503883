import { createContext } from 'react';

type Brand = {
  name: 'WMG' | 'ADA' | null;
  title: string;
};

const defaultBrandContext: Brand = {
  name: null,
  title: '',
};

const BrandContext = createContext<Brand>(defaultBrandContext);

BrandContext.displayName = 'BrandContext';

export { BrandContext, defaultBrandContext };
export type { Brand };

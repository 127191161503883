import type { ReactNode } from 'react';

type PopoverProps = {
  children: ReactNode;
};

export function Sidebar({ children }: PopoverProps) {
  return (
    <div
      className=" tailwind-preflight flex flex-col w-[4.5rem] h-screen bg-default text-default gap-sm sticky top-0"
      data-testid="main-nav-sidebar"
    >
      {children}
    </div>
  );
}

Sidebar.displayName = 'Sidebar';

import type { LabelRow } from '~/modules/settings/types';

export const GroupInfo = ({ data }: { data: LabelRow }) => {
  const { groupsNames } = data.original;

  if (!groupsNames?.length) return null;

  const statusBaseCls = 'rounded-full flex-shrink-0 basis-4 w-sm h-sm ml-sm';

  return (
    <div className="flex items-center">
      <span className="flex-shrink-0 max-w-fit">{groupsNames[0]}</span>
      {groupsNames.length > 1 ? (
        <span className="ml-sm px-xs basis-4 rounded-xs leading-5 bg-level-05 text-body-xs text-center">
          +{groupsNames.length - 1}
        </span>
      ) : null}
      <span className={`${statusBaseCls}`} />
    </div>
  );
};

GroupInfo.displayName = 'GroupInfo';

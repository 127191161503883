import { type PropsWithChildren } from 'react';

import { BrandContext, defaultBrandContext } from '~/modules/branding';

import type { Brand } from '../contexts/BrandContext.tsx';

type BrandProviderProps = {
  localBrand: string;
  hostname: string;
};

const BrandProvider = ({
  children,
  localBrand,
  hostname,
}: PropsWithChildren<BrandProviderProps>) => {
  const brand: Brand = defaultBrandContext;

  const wmgOneDomains = [
    'one.wmg.com',
    'staging.one.wmg.com',
    'dev.one.wmg.com',
    localBrand === 'WMG' && 'localhost',
  ];
  const adaHubDomains = [
    'partners.ada-music.com',
    'staging.partners.ada-music.com',
    'dev.partners.ada-music.com',
    localBrand === 'ADA' && 'localhost',
  ];

  if (wmgOneDomains.includes(hostname)) {
    brand.name = 'WMG';
    brand.title = 'WMG One';
  } else if (adaHubDomains.includes(hostname)) {
    brand.name = 'ADA';
    brand.title = 'ADA';
  } else {
    brand.name = null;
    brand.title = '';
  }

  return (
    <BrandContext.Provider value={brand}>{children}</BrandContext.Provider>
  );
};

BrandProvider.displayName = 'BrandProvider';

export { BrandProvider };
export type { BrandProviderProps };

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { LabelRow } from '~/modules/settings/types';

export const LabelInfo = ({ data }: { data: LabelRow }) => {
  const { name, isActive } = data.original;
  const statusBaseCls = 'rounded-full flex-shrink-0 basis-4 w-sm h-sm ml-sm';
  const statusCls = isActive ? 'bg-success-default' : 'bg-error-default';

  const { t } = useTranslation('settings');

  return (
    <Link
      className="flex items-center"
      to={`${data.original.id}`}
      aria-label={t('viewLabelDetails')}
    >
      <span className="flex-shrink-0 max-w-fit">{name}</span>
      <span className={`${statusBaseCls} ${statusCls}`} />
    </Link>
  );
};

LabelInfo.displayName = 'LabelInfo';

import type React from 'react';
import type { HTMLAttributes } from 'react';

import { mergeClasses } from '~/lib/mergeClasses';

export interface AvatarProps extends HTMLAttributes<HTMLSpanElement> {
  name: string;
}

const getInitials = (name: string) => {
  const initials = name
    .split(' ')
    .map((word) => word[0])
    .join('');
  return initials.toLocaleUpperCase();
};

export const Avatar: React.FC<AvatarProps> = ({
  name,
  className,
  ...props
}) => {
  return (
    <span
      className={mergeClasses(
        'flex',
        'items-center',
        'justify-center',
        'flex-shrink-0',
        'text-strong-on-light',
        'font-semibold',
        'h-2xl',
        'w-2xl',
        'rounded-full',
        'bg-tertiary-default',
        'hover:bg-tertiary-hover',
        'focus:outline-none',
        'focus:bg-tertiary-hover',
        'active:bg-tertiary-pressed',
        className,
      )}
      {...props}
    >
      {getInitials(name)}
    </span>
  );
};

Avatar.displayName = 'Avatar';

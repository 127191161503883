import { useTranslation } from 'react-i18next';

export function Header() {
  const { t } = useTranslation('settings');

  return (
    <header>
      <h1 className="py-sm text-display-sm">{t('title')}</h1>
    </header>
  );
}

Header.displayName = 'Header';

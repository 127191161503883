import { useTranslation } from 'react-i18next';

export function ErrorPage() {
  const { t } = useTranslation('errors');

  return (
    <div id="error-page">
      <h1>{t('oldErrorPage.title')}</h1>
      <p>{t('oldErrorPage.message')}</p>
    </div>
  );
}

ErrorPage.displayName = 'ErrorPage';
